import { Language } from 'store/app/types'
import { ELProduct, NGProduct } from 'types/products'
import { Region } from 'api/types.ts'
import resources from 'translations/dutch'

// GENERAL TYPES

export type Address = {
  countryCode: 'BE'
  postalCode: number | string
  townCode: number
  townName: string
  streetName: string
  streetNumber: string
  streetBox: string
}

export type AddressTile = {
  billingNumber: string
  address: Address
  terminated: boolean
  customerId: Contract['customerId']
  isProfessional: boolean
  contracts: {
    electricity: boolean | null
    gas: boolean | null
  }
  terminatedContracts: {
    electricity: boolean | null
    gas: boolean | null
  }
  subscriptionDate: Contract['detail']['subscriptionDate']
}

type Attestation = {
  ean: string
  fromDate: string
  toDate: string
}

export type Company = {
  name: string
  vatApplication: boolean
  enterpriseNumber: string
  legalForm: LegalForm | string
}

export type Contact = {
  contactMode: ContactMode
  email: string
  phoneMobile: string
}

export type BillingContract = {
  id: string
  customerId: string
  type: ContractType.BILLING
  deliveryMode: ContactMode
  detail: {
    status: ContractStatus
    subscriptionDate: string
    contractualStartDate: string
    effectiveEndDate?: string
  }
  events: {
    billingCycleViewed: boolean
  }
}

export type Contract = {
  id: string
  customerId: string
  invoicedInContractNr: string
  ean: string
  type: ContractType
  producer: string
  deliveryMode?: ContactMode
  detail: {
    status: ContractStatus
    subscriptionDate: string
    contractualStartDate: string
    effectiveEndDate: string
    productCode: ELProduct | NGProduct
    energyType: EnergyType
    instalment: number
    priceList: string
  }
  address: Address
  region: Region
  digitalMeter?: boolean
  smartMeter?: boolean
  dynamicTariff?: boolean
  monthlyBilling?: boolean
  previousIndex: PreviousIndex
  readingFrequency: ReadingFrequency
  events?: {
    billingCycleViewed: boolean
  }
}

export type Coordinates = {
  lat: number
  long: number
}

export type Customer = {
  id: string
  language: Language
  clientType?: ClientType
  customerStatus?: CustomerStatus
  customerCategory?: CustomerCategory
  company?: Company
  person: Person
  producer: string
  address: Address
  contact: Contact
  contractNumbers: string[]
  contracts: Contract[][]
  attestations?: Attestation[]
  paymentDetails: {
    directDebit: boolean
    iban: string
    mandateNumber: string
  }
}

export type Email = {
  subject: string
  message: string
}

export type FaqQuestion = {
  id: number
  question: string
  answer: string
  // categories: string[]
  isFeatured?: boolean
}

export type FaqCategory = {
  id: number
  name: string
  slug: string
  order: number
  locale: string
  questions: FaqQuestion[]
}

export type Invoice = {
  date: string
  expireDate: string
  type: InvoiceType
  payed: boolean
  paymentURL: string
  invoiceURL: string
  mandateNbr: string
  amount: string
  invoiceNbr: string
}

type CustomerGeneralDocument = {
  id: string
  date: string
  amount: number
  runningBalance: number
  paid: boolean
  matchingNumber: number
  directDebitSent: boolean
  paymentSent: boolean
  accountingEntity: AccountingEntity
}

export type CustomerInvoice = {
  documentType: DocumentType.INVOICE
  pdfUrl: string
  paymentUrl: string
  invoiceType: InvoiceType
  dueDate: string
  electricityContractNumber: string
  invoiceNumber: number
} & CustomerGeneralDocument

export type CustomerPayment = {
  documentType: DocumentType.PAYMENT
} & CustomerGeneralDocument

export type CustomerAccountingDocument = CustomerInvoice | CustomerPayment
export type InvoiceTypeTranslationKey = keyof typeof resources.billing.invoiceTypes

export type Image = {
  url?: string
  name?: string
}

export type ImageObject = {
  header?: Image
  profile?: Image
  extra?: Image[]
}

export type Person = {
  firstName: string
  lastName: string
  birthDate?: string
}

export type PreviousIndex = {
  meterNumber: string
  meterType: MeterType
  registers: Register[]
}

type TranslatedValue<T> = {
  nl: T
  fr: T
}

export type Producer = {
  id: string
  enabled: boolean
  salesforceId: string
  isFeatured: TranslatedValue<boolean>
  isPopular: TranslatedValue<boolean>
  name: TranslatedValue<string>
  slug: string
  parentSlug: string
  description: TranslatedValue<string>
  longDescription?: TranslatedValue<string>
  facts?: TranslatedValue<string[]>
  location: TranslatedValue<string>
  order: number
  state: ProducerState
  energyType: ProducerEnergyType
  energyRegion: string
  energyUsed: number
  currentCapacity: number
  minOccupation: number
  maxCapacity: number
  images: ImageObject
  coordinates: Coordinates
  isSoldOut: boolean
}

export type Product = {
  electricity: ELProduct
  gas: NGProduct
}

export type Referral = {
  closeDate?: string // ISO Date
  firstName: string
  lastName: string
  pending: boolean
  reward?: string
  createdAt: string // ISO Data
  simulationSalesOffice?: string
}

export type Register = {
  registerName: string
  direction: Direction
  timeframeCode: TimeframeCode
}

export type ReservedContract = {
  id: string
  consumption: number
}

export type Town = {
  postalCode: number
  townName: string
  townCode: number
  mainTownName: string
  geoCode: string
  languageCode: 'NL' | 'FR'
}

export type Translation = {
  slug: string
  name: string
  description: string
  longDescription: string
  rawLongDescription: string
  facts: string[]
}

// ENUMS

export enum AccountingEntity {
  CUSTOMER = 'customer',
  SUPPLIER = 'supplier'
}

export enum ClientType {
  PROFESSIONAL = 'Professional',
  RESIDENTIAL = 'Residential'
}

export enum ContactMode {
  EMAIL = 'EMAIL',
  POST = 'POST'
}

export enum ContractStatus {
  ABANDONED = 'Abandoned',
  ACTIVE = 'Active',
  CANCELLED = 'Cancelled',
  EFFECTIVE = 'Effective',
  ERROR = 'Error',
  ILC_ONGOING = 'ILConGoing',
  INACTIVE = 'Inactive',
  RECOVERY = 'Recovery',
  REJECTED = 'Rejected',
  TERMINATED = 'Terminated',
  UNKNOWN_POINT = 'UnknownPoint',
  VALIDATION_REJECT = 'ValidationReject',
  WAITING_14_DAYS = 'waiting-14d',
  WRONG_ENERGY_PRODUCT = 'WrongEnergyProduct'
}

export enum SimplifiedContractStatus {
  INACTIVE = 'inactive',
  IN_PROGRESS = 'inProgress',
  CANCELLED = 'cancelled',
  TERMINATED = 'terminated',
  ACTIVE = 'active'
}

export enum ContractType {
  BILLING = 'billing',
  ELECTRICITY = 'electricity',
  GAS = 'gas'
}

export enum CustomerCategory {
  B2B = 'B2B',
  RES = 'RES',
  SME = 'SME',
  SOHO = 'SOHO'
}

export enum CustomerStatus {
  VALIDATED = 'Validated',
  NOT_VALIDATED = 'Not Validated'
}

export enum DeliveryMode {
  EMAIL = 'Email',
  POST = 'Post'
}

export enum Direction {
  PRODUCTION = 'PRODUCTION',
  CONSUMPTION = 'CONSUMPTION'
}

export enum EnergyType {
  ELECTRICITY = 'EL',
  GAS = 'NG'
}

export enum InvoiceType {
  INSTALMENT = 'VFVF',
  INSTALMENT_CREDITNOTE = 'CNVF',
  SETTLEMENT = 'VFAF',
  SETTLEMENT_CREDITNOTE = 'CNAF',
  FINAL_SETTLEMENT = 'VFEA',
  FINAL_SETTLEMENT_CREDITNOTE = 'CNEA',
  INJECTION_SETTLEMENT_PRO = 'VFAI',
  INJECTION_SETTLEMENT_PRO_CREDITNOTE = 'CNAI',
  INJECTION = 'VFIN',
  INJECTION_CREDITNOTE = 'CNIN',
  BUDGET_METER = 'VFBM',
  BUDGET_METER_CREDITNOTE = 'CNBM',
  RECTIFICATION = 'VFRE',
  RECTIFICATION_CREDITNOTE = 'CNRE',
  CANCEL = 'VFCA',
  CANCEL_CREDITNOTE = 'CNCA',
  DIVERSE = 'VFDV',
  DIVERSE_CREDITNOTE = 'CNDV',
  MANUAL_CREDITNOTE = 'CNMN',
  CASHBACK = 'BCNMN',
  PURCHASE_INJECTION = 'AKIN',
  PURCHASE_INJECTION_CREDITNOTE = 'ACIN',
  PURCHASE_INJECTION_PRO = 'AKAI',
  PURCHASE_INJECTION_PRO_CREDITNOTE = 'ACAI',
  MISC_INVOICE = 'VFAK',
  MISC_CREDITNOTE = 'CNAK'
}

export enum LegalForm {
  ACP = 'ACP',
  VME = 'VME',
  BVBA = 'BVBA',
  SPRL = 'SPRL',
  BV = 'BV',
  SRL = 'SRL',
  NV = 'NV',
  SA = 'SA',
  GCV = 'GCV',
  SCS = 'SCS',
  CommV = 'CommV',
  SComm = 'SComm',
  CVBA = 'CVBA',
  SCRL = 'SCRL',
  VZW = 'VZW',
  ASBL = 'ASBL',
  EBVBA = 'EBVBA',
  SPRLU = 'SPRLU',
  VOF = 'VOF',
  SNC = 'SNC',
  INDIVIDUAL_NL = 'Eenmanszaak',
  INDIVIDUAL_FR = 'Entreprise individuelle'
}

export enum LegalFormSelectOptionKeys {
  BVBA = 'BVBA',
  BV = 'BV',
  NV = 'NV',
  GCV = 'GCV',
  COMMV = 'CommV',
  CVBA = 'CVBA',
  VZW = 'VZW',
  EBVBA = 'EBVBA',
  VOF = 'VOF',
  Eenmanszaak = 'Eenmanszaak',
  VME = 'VME'
}

export enum SalesforceLegalForm {
  ACP_VME = 'ACP - VME',
  ASBL_VZW = 'ASBL - VZW',
  INDIVIDUELLE_EENMANSZAAK = 'Entreprise Individuelle - Eenmanszaak',
  SA_NV = 'SA - NV',
  SCRL_CVBA = 'SCRL - CVBA',
  SCS_GCV = 'SCS - GCV',
  SCOMM_COMMV = 'SComm - CommV',
  SNC_VOF = 'SNC - VOF',
  SPRL_BVBA = 'SPRL - BVBA',
  SPRLU_EBVBA = 'SPRLU - EBVBA',
  SRL_BV = 'SRL - BV'
}

export enum MeterType {
  ELECTRONIC_METER = 'ELECTRONIC_METER',
  SMART_METER = 'SMART_METER',
  MECHANICAL_METER = 'MECHANICAL_METER'
}

export enum PaymentMode {
  DIRECT_DEBIT = 'DD',
  BANK_TRANSFER = 'TRA'
}

export enum ProducerEnergyType {
  BIO = 'Bio Energy',
  WATER = 'Water',
  SOLAR = 'Solar',
  WIND = 'Wind'
}

export enum ProducerState {
  AVAILABlE = 'available',
  SOLD_OUT = 'soldOut',
  RESERVED = 'reserved'
}

export enum ProductType {
  ELECTRICITY = 'electricity',
  GAS = 'gas'
}

export enum ReadingFrequency {
  CONTINUOUS = 'CONTINUOUS',
  MONTH = 'MONTH',
  YEAR = 'YEAR'
}

export enum TimeframeCode {
  HIGH = 'HIGH',
  LOW = 'LOW',
  NIGHT_EXCLUSIVE = 'NIGHT_EXCLUSIVE',
  NOT_USED = 'NOT_USED',
  TOTAL_HOUR = 'TOTAL_HOUR'
}

export enum VatApplication {
  SUBJECT_TO = 'Subject to',
  NOT_SUBJECT_TO = 'Not Subject to'
}

export enum DocumentType {
  INVOICE = 'invoice',
  PAYMENT = 'payment'
}
