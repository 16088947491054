import styles from 'pages/App/Friends/Friends.module.scss'
import classNames from 'classnames'
import { ActionCardProps } from 'pages/App/Friends/components/ActionCard/types'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Button, Heading } from '@boltenergy-be/design-system'
import { DAYJS_THRESHOLDS } from 'constants/referral.ts'
import { useSelector } from 'react-redux'
import { Store } from 'store/types.ts'
import { Language } from 'store/app/types.ts'
import DurationTimestamp from 'components/DurationTimestamp/DurationTimestamp.tsx'
import { BlocksRenderer } from '@strapi/blocks-react-renderer'
import Drawer from 'components/Drawer/Drawer.tsx'
import { useState } from 'react'

dayjs.extend(relativeTime, {
  thresholds: DAYJS_THRESHOLDS,
  rounding: Math.floor
})

const ActionCard = ({ activeAction }: ActionCardProps) => {
  // i18n
  const { t } = useTranslation('referral')

  // REDUX
  const { language } = useSelector((store: Store) => store.app)

  // Dayjs
  dayjs.locale(language === Language.FR ? 'fr' : 'nl')

  // Local state
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)

  return (
    <>
      <section className={styles['action-card-container']}>
        <div className={styles['action-card']}>
          <div className={styles['action-card-description']}>
            <span className={classNames(styles['action-card-timer'], styles['extra-bold'])}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.99984 0.833252C5.05229 0.833252 1.0415 4.84404 1.0415 9.79158C1.0415 14.7392 5.05229 18.7499 9.99984 18.7499C14.9474 18.7499 18.9582 14.7392 18.9582 9.79158C18.9582 4.84404 14.9474 0.833252 9.99984 0.833252ZM10.8332 5.83325C10.8332 5.37301 10.4601 4.99992 9.99984 4.99992C9.5396 4.99992 9.1665 5.37301 9.1665 5.83325V9.99992C9.1665 10.269 9.29648 10.5216 9.51547 10.678L12.4321 12.7614C12.8067 13.0289 13.3271 12.9421 13.5946 12.5676C13.8621 12.1931 13.7754 11.6726 13.4009 11.4051L10.8332 9.57107V5.83325Z"
                  fill="currentColor"
                />
              </svg>
              <DurationTimestamp endDate={activeAction.attributes.end} />
            </span>

            <Heading as="h2" variant="h4">
              {activeAction.attributes.shortContent.title}
            </Heading>

            <div>
              <BlocksRenderer content={activeAction.attributes.shortContent.description} />
            </div>

            <Button type="button" variant="secondary-on-accent" onClick={() => setOpenDrawer(true)}>
              {t('actions.activeAction.card.moreInfo')}
            </Button>
          </div>

          <picture className={styles['action-card-image']}>
            <img src={activeAction.attributes.image.data.attributes.url} alt="" />
          </picture>
        </div>
      </section>

      {!!activeAction && (
        <Drawer
          isOpen={openDrawer}
          onRequestClose={() => setOpenDrawer(false)}
          title={activeAction.attributes.conditions.title}
          contentClassName={styles['drawer-content']}
        >
          <BlocksRenderer
            content={activeAction.attributes.conditions.description}
            blocks={{
              heading: ({ children, level }) => (
                <Heading as={`h${level}`} variant={level === 1 ? 'h2' : 'h6'}>
                  {children}
                </Heading>
              )
            }}
          />
        </Drawer>
      )}
    </>
  )
}

export default ActionCard
